class ZIDXAccountSendListingAlerts implements ZIDXPage{
    constructor() {
    }
    getPageClass(){
        return "zidxAccountSendListingAlertsContainer";
    }
    getMatchURL(){
        return "/account/saved-searches/send";
    }
    render(){
        // console.log("send saved search");
    }
}